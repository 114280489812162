<template>
  <v-container grid-list-md text-xs-center fluid class="fill-height">
    <v-card>
      <v-card-title>Invia richiesta di intervento</v-card-title>
      <v-card-subtitle>
        Istruzioni
        <v-icon @click="dialog = true" color="primary">info</v-icon>
      </v-card-subtitle>
      <v-card-text>
        <v-form v-model="valid" v-if="!inviato">
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-text-field
                class="my-1"
                dense
                autocomplete="off"
                prepend-icon="home"
                v-model="azienda"
                :rules="aziendaRules"
                :counter="25"
                label="Azienda"
                required
              ></v-text-field>
            </v-row>
            <v-row class="my-1">
              <v-text-field
                dense
                autocomplete="off"
                prepend-icon="toc"
                v-model="matricola"
                :rules="matricolaRules"
                :counter="10"
                label="Matricola"
              ></v-text-field>
            </v-row>
            <v-row class="my-1">
              <v-text-field
                dense
                autocomplete="off"
                prepend-icon="textsms"
                v-model="messaggio"
                :rules="messaggioRules"
                label="Motivo della richiesta"
                :counter="60"
                required
              ></v-text-field>
            </v-row>
            <v-row class="my-1">
              <v-text-field
                dense
                autocomplete="off"
                type="number"
                :rules="contattoRules"
                prepend-icon="phone"
                v-model="contatto"
                label="recapito telefonico"
                :counter="12"
                required
              ></v-text-field>
            </v-row>
            <v-row class="my-0" v-if="1 == 0">
              <v-col cols="4" class="my-0">
                <v-file-input
                  prepend-icon="mdi mdi-camera"
                  v-model="scattafoto"
                  class="my-0"
                  sm="3"
                  label="Foto"
                  outlined
                  dense
                  capture="user"
                  accept="image/*"
                  @change="onFileChange"
                  hide-input
                ></v-file-input
              ></v-col>
              <v-col cols="4" class="my-0">
                <v-img :src="scattafotourl"> </v-img>
              </v-col>
            </v-row>
            <v-row align="center" justify="space-around" class="my-0">
              <v-btn
                :disabled="!valid"
                color="success"
                @click="inviaRichiesta"
                raised
                rounded
                x-large
                >Invia la richiesta</v-btn
              ></v-row
            >
            <v-row>
              <p>
                <i
                  >I dati inviati verranno utilizzati unicamente per la gestione
                  della richiesta.</i
                >
              </p></v-row
            >
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- visualizza conferma -->
    <v-row row wrap class="justify-center" v-if="inviato">
      <v-col xs10 md8>
        <v-card transition="fade-transition">
          <v-card-title
            primary-title
            class="justify-center"
            v-bind:class="colorerisposta"
          >
            <div>
              <h4 class="headline mb-3">{{ rispostainvio }}</h4>
              <div>{{ card_text }}</div>
            </div>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-3" align="center" justify="space-around">
      <v-btn href="tel:0373780377">Tel 0373780377</v-btn>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline justify-center">Istruzioni</v-card-title>
        <v-card-text>
          Per segnalare una richiesta di intervento, compila il modulo indicando
          <b>l'azienda</b> e il <b>motivo</b> della richiesta. <br />Inserendo
          anche la <b>matricola</b> del distributore, la richiesta viene
          inoltrata direttamente all'agente di zona. <br />Per conoscere la
          matricola trova una etichetta come questa sul distributore
          <br />
          <img src="/img/matricola.jpg" alt="matricola" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"
            >Chiudi</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    valid: false,
    azienda: "",
    matricola: "",
    messaggio: "",
    contatto: "",
    scattafoto: undefined,
    scattafotourl: undefined,
    rispostainvio: "",
    colorerisposta: "success",
    inviato: false,
    dialog: false,
    url: "https://api.eoscaffe.it:8443/help",
    //url: "http://localhost:5554/help",

    aziendaRules: [
      (v) => !!v || "motivo obbligatorio",
      (v) => v.length <= 25 || "Lunghezza massima 25 caratteri",
      (v) => v.length > 2 || "Nome troppo corto",
    ],
    matricolaRules: [
      (v) =>
        v.match(/((^\d{3}\.\d{4,6}$)|(^\d{8}$)|(^$))/g) ||
        "La matricola è un numero tipo 123.4567 o un numero di 8 cifre. Se non la trovi non inserire nulla",
    ],
    messaggioRules: [
      (v) => !!v || "Dato obbligatorio",
      (v) => v.length <= 40 || "Lunghezza massima 40 caratteri",
      (v) =>
        v.length >= 5 || "Per favore cerca di essere più specifico. grazie",
    ],
    contattoRules: [
      (v) => !!v || "telefono obbligatorio",
      (v) => v.length <= 12 || "Lunghezza massima 12 caratteri",
    ],
  }),
  mounted: function () {
    this.matricola = this.$store.state.matricola;
  },
  methods: {
    inviaRichiesta: function () {
      var azienda = this.azienda;
      var matricola = this.matricola;
      var messaggio = this.messaggio;
      var contatto = this.contatto;
      var scattafotourl = this.scattafotourl;

      const dateTime = +new Date();
      const timestamp = Math.floor(dateTime);
      this.valid = false;
      axios
        .post(this.url, {
          datainvio: timestamp,
          azienda: azienda,
          matricola: matricola,
          messaggio: messaggio,
          contatto: contatto,
          immagine: scattafotourl,
        })
        .then((response) => {
          console.log("inviato: " + response);
          this.inviato = true;
          this.rispostainvio =
            "Richiesta inoltrata. Interverremo al più presto. Grazie.";
          this.colorerisposta = "success";
        })
        .catch((e) => {
          console.log("errore " + e);
          this.inviato = true;
          this.rispostainvio =
            "Ci sono problemi ad inoltrare la richiesta. Riprova più tardi";
          this.colorerisposta = "error";
        });
    },
    //crea immagine per scattafoto
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // console.log(this.scattafotourl);
        this.scattafotourl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      if (!file) {
        return;
      }

      this.createImage(file);
    },
  },
};
</script>
<template>
  <v-container fluid class="fill-height">
    <h3>Contattaci qui</h3>
    <v-col cols="12">
      <v-card color="amber lighten-3" shaped>
        <v-card-actions>
          <v-icon>mdi-phone</v-icon>
          <v-btn text href="tel:0373780377">0373.780.377</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="amber lighten-3" shaped>
        <v-card-actions>
          <v-icon>mdi-email</v-icon>
          <v-btn text href="mailto:info@eoscaffe.it">info@eoscaffe.it</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="amber lighten-3" shaped>
        <v-card-actions>
          <v-icon>mdi-web</v-icon>
          <v-btn text href="https://www.eoscaffe.it">www.eoscaffe.it</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="amber lighten-3" shaped>
        <v-card-actions>
          <v-icon>mdi-cart</v-icon>
          <v-btn text href="https://store.eoscaffe.it"
            >Ordina su store.eoscaffe.it</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="amber lighten-3" shaped>
        <v-card-title>Seguici sui social @eoscaffe</v-card-title>
        <v-card-actions>
          <v-btn text href="https://www.facebook.com/eoscaffe">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn text href="https://instagram.com/eoscaffe">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
          <v-btn text href="https://it.linkedin.com/company/eoscaffe">
            <v-icon>mdi-linkedin</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>
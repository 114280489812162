import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'md'
  },
  theme: {
    primary: '#ff9800',
    secondary: '#607d8b',
    accent: '#ffc107',
    error: '#f44336',
    warning: '#ffeb3b',
    info: '#03a9f4',
    success: '#4caf50'
  }
}

export default new Vuetify(opts);
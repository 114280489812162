<template>
  <v-row>
    <v-col xs12 sm6 offset-sm3>
      <link href="https://it.snatchbot.me/sdk/webchat.css" rel="stylesheet" type="text/css">
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
  }),
  methods: {
    },
  mounted() {
    let botscript = document.createElement('script')
    botscript.setAttribute('src','https://it.snatchbot.me/sdk/webchat.min.js')
    let botscript2 = document.createElement('script')
    botscript2.innerHTML=" Init('?botID=49742&appID=webchat', 600, 600, 'https://dvgpba5hywmpo.cloudfront.net/media/image/ADVKXBQWwQYisrdWxfT2uimin', 'rectangle', '#FDFD4D', 90, 90, 62.99999999999999, '', '1', '#FFFFFF', '#000000', 1);"
  }

};
</script>

import firebase from 'firebase/app'
import "firebase/analytics"

var firebaseConfig = {
    apiKey: "AIzaSyCLZOOaBc3iICmxwKRJH4AGdrF_O2h3lrE",
    authDomain: "eosapp-40eb3.firebaseapp.com",
    databaseURL: "https://eosapp-40eb3.firebaseio.com",
    projectId: "eosapp-40eb3",
    storageBucket: "eosapp-40eb3.appspot.com",
    messagingSenderId: "3134693029",
    appId: "1:3134693029:web:da1986f28e2df53aaab74c",
    measurementId: "G-TP4CJZ12HS"
};

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default {
    firebase
}
<template>
  <v-row align="center">
    <v-col cols="12" xs12 sm6 offset-sm3>
      <v-card>
        <v-card-title primary-title class="justify-center">
          <div>
            <v-img
              class="my-2"
              justify-center
              src="/img/logo2020.png"
              width="300px"
              aspect-ratio="1"
            ></v-img>
            <br />
            <div>{{ card_text }}</div>
          </div>
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn color="orange" @click="email">info@eoscaffe.it</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    card_text: "App v. 0.9 - Beta",
  }),
  methods: {
    email: () => {
      window.location.href = "mailto:info@eoscaffe.it";
    },
  },
};
</script>

import vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'

import Ads from 'vue-google-adsense'
const firebase = require("./firebase_config")
import vueGeolocation from 'vue-browser-geolocation';
vue.use(vueGeolocation);

const moment = require('moment')
require('moment/locale/it')
vue.use(require('vue-moment'), {
  moment
})

import vuex from 'vuex'
vue.use(vuex)
import store from './store/store'

/* import vueGtag from 'vue-gtag';
vue.use(vueGtag, {
  config: {
    id: "UA-149610408-1"
  }
}, router); */

vue.prototype.$firebase = firebase; // con questo non devo fare import in ogni pagina

vue.use(require('vue-script2'))

vue.use(Ads.Adsense)
vue.use(Ads.InArticleAdsense)
vue.use(Ads.InFeedAdsense)

vue.config.productionTip = false

new vue({
  router,
  store,
  vuetify,
  firebase,
  render: h => h(App)
}).$mount('#app')
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card color="amber lighten-3" shaped>
          <v-card-title
            >Izano
            <v-btn text href="https://g.page/eoscaffe-izano" target="_blank">
              <v-icon>mdi-map-marker</v-icon>
            </v-btn></v-card-title
          >
          <v-card-subtitle>Sede Legale e operativa</v-card-subtitle>
          <v-card-text>Via Delle Industrie 16 - 26010 Izano (CR)</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card color="amber lighten-3" shaped>
          <v-card-title
            >Piacenza
            <v-btn text href="https://g.page/eos-srl-piacenza" target="_blank">
              <v-icon>mdi-map-marker</v-icon>
            </v-btn></v-card-title
          >
          <v-card-subtitle>Sede operativa</v-card-subtitle>
          <v-card-text>Via Caorsana 129 - 29122 Piacenza (PC)</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card color="amber lighten-3" shaped>
          <v-card-title
            >Crema
            <v-btn text href="https://g.page/EosPoint" target="_blank">
              <v-icon>mdi-map-marker</v-icon>
            </v-btn></v-card-title
          >
          <v-card-subtitle>Negozio Capsule e cialde caffè</v-card-subtitle>
          <v-card-text>Via Stazione 5/a - 26013 Crema (CR)</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card color="amber lighten-3" shaped>
          <v-card-title
            >Crema
            <v-btn
              text
              href="https://g.page/eos-24h-crema-xxsettembre"
              target="_blank"
            >
              <v-icon>mdi-map-marker</v-icon>
            </v-btn></v-card-title
          >
          <v-card-subtitle>Negozio Automatico 24h</v-card-subtitle>
          <v-card-text>Via XX Settembre 114a - 26013 Crema (CR)</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card color="amber lighten-3" shaped>
          <v-card-title
            >Crema
            <v-btn
              text
              href="https://g.page/eos-24h-crema-garibaldi"
              target="_blank"
            >
              <v-icon>mdi-map-marker</v-icon>
            </v-btn></v-card-title
          >
          <v-card-subtitle>Negozio Automatico 24h</v-card-subtitle>
          <v-card-text>Piazza Garibaldi 77 114a - 26013 Crema (CR)</v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/home.vue'
import Help from './views/help.vue'
import Info from './views/info.vue'
import Hello from './views/hello.vue'
import Vota from './views/vota.vue'
import Contatti from './views/contatti.vue'
import Dove from './views/dovesiamo.vue'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter(to, from, next) {
        //se carico la pagina azzero la matricola in vuex
        store.commit("updateMatricolaAttuale", "");
        next()
      },
      scrollBehavior() {
        return {
          x: 0,
          y: 0
        }
      }
    },
    {
      path: '/info',
      name: 'info',
      component: Info
    },
    {
      path: '/help',
      name: 'help',
      component: Help
    },
    {
      path: '/hello',
      name: 'hello',
      component: Hello
    },
    {
      path: '/vota',
      name: 'vota',
      component: Vota
    },
    {
      path: '/contatti',
      name: 'contatti',
      component: Contatti
    },
    {
      path: '/dovesiamo',
      name: 'dovesiamo',
      component: Dove
    }
  ]
})

export default router
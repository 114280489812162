<template>
  <v-container>
    <!-- blocco scan matricola -->
    <v-row class="text-center" justify="center" v-if="!datiDA.MATRICOLA">
      <v-col cols="12" md="8">
        <v-card elevation="20" shaped class="pb-15">
          <v-card-title></v-card-title>
          <v-card-subtitle>
            Premi la tazzina
            <br />e collegati al nostro distributore
          </v-card-subtitle>
          <v-card-text>
            <div class="my-4 text-center">
              <v-btn
                color="orange"
                @click="avviaInserimentoManuale"
                fab
                x-large
                dark
              >
                <v-icon x-large>mdi-coffee</v-icon>
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions v-if="1 == 0">
            <v-spacer></v-spacer>
            <v-btn color="orange" text @click="avviaInserimentoManuale" hidden
              >Inserimento Manuale</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!--- blocco scheda matricola -->
    <v-card class="mx-auto" max-width="500" v-if="datiDA.MATRICOLA">
      <v-card-title class="py-0">
        Sei collegato al distributore EOS
        <br />
        <b>Matricola {{ datiDA.MATRICOLA }}</b>
      </v-card-title>
      <v-container fluid class="py-0">
        <v-row dense>
          <v-list class="py-0">
            <v-subheader
              >{{ datiDA.MOD_COD }} - {{ datiDA.MODELLO }}</v-subheader
            >
            <v-list-item-group color="primary" class="py-0">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green">mdi-thumb-up</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    Trasmette i dati regolarmente
                    <br />all'agenzia delle entrate
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <a
                      target="_blank"
                      href="https://www.eoscaffe.it/agenzia-entrate/"
                      >Scopri di più</a
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green">mdi-cash</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Accetta
                    <template v-if="datiDA.MONETA"> <b>Monete</b> </template
                    >&nbsp;
                    <template v-if="datiDA.BANCONOTE">
                      <b>e Banconote</b>
                    </template>
                    <template v-if="datiDA.RENDIRESTO"></template>
                    <br />
                    <template v-if="datiDA.NFC">
                      <b>Puoi acquistare con App</b>
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green">mdi-basket-fill</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <b>Ultimo Passaggio effettuato:</b>
                    <br />
                    {{ datiDA.ULTIMO_PASSAGGIO | moment("dddd, D MMMM YYYY") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green">mdi-run</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <b>Prossimo passaggio previsto:</b>
                    <br />
                    {{
                      datiDA.PASSAGGIO_PREVISTO | moment("dddd, D MMMM YYYY")
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="green ">mdi-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <b>Ultimo intervento tecnico:</b>
                    <br />
                    {{ datiDA.ULTIMO_INTERVENTO | moment("from", "now") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="datiDA.NCHIARIF > 0">
                <v-list-item-icon>
                  <v-icon color="red">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <b>C'è una chiamata di rifornimento aperta il</b>
                    <br />
                    {{ datiDA.DATACHIARIF | moment("dddd, D MMMM YYYY") }}
                    <br />sarà evasa al più presto
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="datiDA.NCHIAGUA > 0">
                <v-list-item-icon>
                  <v-icon color="red">mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <b>C'è una chiamata di guasto aperta il</b>
                    <br />
                    {{ datiDA.DATACHIAGUA | moment("dddd, D MMMM YYYY") }}
                    <br />sarà evasa al più presto
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-row>
        <v-row align="center" justify="space-around">
          <v-btn
            class="my-4"
            color="red"
            text
            outlined
            raised
            rounded
            x-large
            @click="richiediAssistenza"
            v-if="datiDA.MATRICOLA"
            >Richiedi Assistenza</v-btn
          >
        </v-row>
      </v-container>
    </v-card>
    <v-btn
      class="my-8"
      color="gray"
      text
      @click="nuovaRicerca"
      v-if="datiDA.MATRICOLA"
      >Nuova Ricerca</v-btn
    >

    <!--- dialogScanner --->
    <div class="text-center">
      <v-dialog
        v-model="dialogScan"
        width="500"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Scansiona il barcode
            <v-img
              class="white--text align-end"
              width="40px"
              src="/img/matricola.jpg"
            ></v-img>
          </v-card-title>

          <video
            id="video"
            width="100%"
            height="300"
            style="border: 1px solid gray"
          ></video>
          <br />
          <label for="sourceSelect">Cambia Camera:</label>
          <v-select
            v-model="selezionacamera"
            :items="listacamera"
            item-text="label"
            item-value="deviceId"
            label="Seleziona camera"
            @change="cambiacamera"
            solo
            dense
          ></v-select>
          <p class="decode-result">
            Matricola rilevata:
            <b>{{ matricolaRilevata }}</b>
          </p>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" text @click="annullaScan">Annulla</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="orange"
              text
              @click="avviaInserimentoManuale"
              v-if="!matricolaRilevata"
              >Inserimento Manuale</v-btn
            >

            <v-btn
              color="primary"
              text
              @click="scanMatricola"
              v-if="matricolaRilevata"
              >Prosegui</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--- dialogManuale --->
      <v-dialog v-model="dialogMan" persistent max-width="400">
        <v-card cols="6">
          <v-card-subtitle class="headline grey lighten-2" primary-title
            >Inserisci la Matricola</v-card-subtitle
          >
          <v-card-text>
            <v-row align="center" justify="space-between">
              <v-col>
                <v-img
                  class="white--text align-center"
                  src="/img/matricola.jpg"
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
          <v-form
            ref="form"
            v-model="isvalidManuale"
            v-on:submit.prevent="onSubmit"
          >
            <v-text-field
              class="my-0 gigante"
              prepend-icon="toc"
              v-model="matricola"
              :error="errorestato"
              :error-messages="erroreMessaggio"
              :rules="matricolaRules"
              label="matricola"
              required
              autocomplete="off"
              font-size="1.5em"
            ></v-text-field>
            <v-card-actions>
              <v-btn color="red" text @click="annullaManuale">Annulla</v-btn>
              <v-spacer dense></v-spacer>
              <v-btn
                @click="inserimentoManuale"
                color="success"
                :disabled="!isvalidManuale"
                >OK</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="ismatricolaInesistente" persistent>
        <v-card>
          <v-card-title class="grey lighten-2" primary-title
            >Matricola inesistente</v-card-title
          >
        </v-card>
        <v-btn @click="closeMatricolainesistente" color="success">OK</v-btn>
      </v-dialog>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
        :opacity="0.8"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<style>
.gigante {
  font-size: 2em;
}
</style>
<script>
//versione light  inserimento manuale avviaInserimentoManuale bottone con v-if="!matricolaRilevata"
//inserimento da foto avviaScan

import axios from "axios";
import { BrowserBarcodeReader } from "@zxing/library";
const codeReader = new BrowserBarcodeReader();
import firebase from "firebase";

export default {
  components: {},
  data: () => ({
    //url: "http://localhost:5554/status",
    url: "https://api.eoscaffe.it:8443/status",
    overlay: false,
    selectedDeviceId: null,
    selezionacamera: localStorage.getItem("selectedCamera"),
    listacamera: [],
    error: null,
    matricola: "",
    matricolaRilevata: null,
    ismatricolaInesistente: false,
    dialogScan: false,
    dialogMan: false,
    isvalidManuale: false,
    cards: null,
    errorestato: false,
    erroreMessaggio: null,
    matricolaRules: [
      (v) => !!v || "Inserisci la matricola",
      (v) =>
        v.match(/((^\d{3}\.\d{4,6}$)|(^\d{8}$)|(^$))/g) ||
        "La matricola è un numero tipo 123.4567 o un numero di 8 cifre. Se non la trovi non inserire nulla",
    ],
    datiDA: [
      {
        MATRICOLA: null,
        MOD_COD: null,
        MODELLO: null,
        ULTIMO_PASSAGGIO: null,
        PASSAGGIO_PREVISTO: null,
        ULTIMO_INTERVENTO: null,
        NCHIARIF: null,
        DATACHIARIF: null,
        NCHIAGUA: null,
        DATACHIAGUA: null,
        RENDIRESTO: null,
        BANCONOTE: null,
        NFC: null,
        MONETA: null,
      },
    ],
  }),
  mounted() {
    //inizializza lista camere
    var ncam = 0;
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
      return;
    }
    // List cameras and microphones.

    var lc = this.listacamera;
    var sc = this.selezionacamera;

    const getcamera = async () => {
      await navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          console.log(devices.length);
          devices.forEach(function (device) {
            if (device.kind == "videoinput" && device.label) {
              ncam++;
              console.log(ncam);
              console.log(
                device.kind + ": " + device.label + " id = " + device.deviceId
              );
              lc.push({
                label: device.label,
                deviceId: device.deviceId,
              });
            }
          });
          console.log("n.cam: " + ncam);
        })
        .then(function () {
          console.log("prima camera: " + lc[0].deviceId);
          if (sc) {
            this.selectedDeviceId = sc;
          } else {
            sc = lc[0].deviceId;
            localStorage.setItem("selectedCamera", lc[0].deviceId);
          }
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
    };
    getcamera();
  },
  methods: {
    cambiacamera: function () {
      this.getcamera();
      console.log("cambiato camera " + this.selezionacamera);
      localStorage.setItem("selectedCamera", this.selezionacamera);
      codeReader.reset();
      this.selectedDeviceId = this.selezionacamera;
      this.avviaScan();
    },
    avviaScan: function () {
      firebase.analytics().logEvent("select_content", {
        content_type: "button",
        content_id: "tazzina",
      });
      firebase.analytics().logEvent("tazzina", {
        content_type: "button",
        content_id: "tazzina",
      });
      this.dialogScan = true;
      this.matricola = null;
      console.log("->" + this.selectedDeviceId);
      codeReader.decodeFromVideoDevice(
        this.selectedDeviceId,
        "video",
        (result, err) => {
          if (result && result.format == 4) {
            console.log(result);
            this.matricolaRilevata = result.text;
            //this.richiediDati(result.text);
          }
          if (err) {
            //  console.error(err);
            this.result = err;
          }
        }
      );
    },
    inserimentoManuale: function () {
      this.dialogMan = false;
      this.overlay = true;
      this.richiediDati(this.matricola);
    },
    scanMatricola: function () {
      this.dialogScan = false;
      this.overlay = true;

      this.richiediDati(this.matricolaRilevata);
    },
    richiediDati: function (matricola) {
      axios
        .get(this.url, {
          params: {
            matricola: matricola,
          },
        })
        .then((response) => {
          console.log("ricevuto: " + JSON.stringify(response.data[0]));
          if (response.data[0].MATRICOLA) {
            this.datiDA = response.data[0];
            this.matricola = this.datiDA.MATRICOLA;
            console.log(this.datiDA);
            this.overlay = false;
            this.dialogScan = false;
          }
        })
        .catch((e) => {
          console.log("errore " + e);
          this.overlay = false;
          this.ismatricolaInesistente = true;
        });
    },
    nuovaRicerca: function () {
      this.matricola = null;
      this.matricolaRilevata = null;
      this.datiDA.MATRICOLA = null;
    },
    richiediAssistenza: function () {
      this.$store.commit("updateMatricolaAttuale", this.matricola);
      this.$router.push({ path: "/help" });
    },
    annullaScan: function () {
      this.dialogScan = false;
      codeReader.reset();
    },
    avviaInserimentoManuale: function () {
      this.dialogScan = false;
      this.dialogMan = true;
    },
    annullaManuale: function () {
      this.matricola = null;
      this.dialogMan = false;
      this.overlay = false;
    },
    closeMatricolainesistente: function () {
      this.ismatricolaInesistente = false;
      this.dialogMan = true;
      this.matricola = "";
    },
  },
  watch: {},
};
</script>

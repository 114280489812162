<template>
  <v-container class="grey lighten-5 text-center">
    <div v-if="!ha_cliccato">
      <v-row class="justify-center">
        <h1>Dicci la tua</h1>
      </v-row>
      <v-row class="justify-center mb-5">
        <h3>La tua opinione conta!</h3>
      </v-row>
      <v-row no-gutters center v-if="!voto" transition="slide-x-transition">
        <v-col
          class="justify-center"
          v-for="n in items.slice().reverse()"
          :key="n"
          cols="3"
          sm="3"
        >
          <div v-bind:style="n == voto ? colore_selezionato : colore_sfondo">
            <v-img
              :src="`img/${n}.png`"
              lazy-src="img/${n}.png"
              aspect-ratio="1"
              style="margin: auto"
              max-width="100"
              max-height="100"
              @click="vota(n)"
            ></v-img>
          </div>
        </v-col>
      </v-row>
      <!--  seconda pagina-->
      <v-row>
        <v-img
          v-if="voto"
          :src="`img/${voto}.png`"
          lazy-src="img/${voto}.png"
          aspect-ratio="1"
          style="margin: auto"
          max-width="20"
          max-height="20"
          @click="vota(n)"
        ></v-img>
      </v-row>
      <v-row v-if="voto">
        <v-col cols="9">
          <v-text-field
            class="my-0"
            prepend-icon="textsms"
            v-model="messaggio"
            label="Vuoi dirci qualcosa?"
            :counter="30"
            :rules="messaggioRules"
            required
            autocomplete="none"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
            block
            :disabled="blocca_invio"
            class="success"
            @click="inviaVoto"
            >Invia</v-btn
          >
        </v-col>
        <v-col xs6 v-if="1 == 1">
          <v-text-field
            class="my-0"
            prepend-icon="mail"
            v-model="email"
            type="email"
            label="Indica l'email se desideri una risposta"
            :counter="30"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4 justify-center" v-if="1 == 2"
        >Hai selezionato: {{ voto }}</v-row
      >
      <v-row
        class="mt-4 justify-center"
        v-if="1 == 2"
        transition="slide-x-transition"
      >
        Cliente: {{ cli_cod }}, matricola {{ matricola }}, canale
        {{ canale }}
      </v-row>
      <v-row v-if="voto" class="mt-4 justify-center">
        <a @click="indietro">Indietro</a>
      </v-row>
    </div>
    <!-- ha cliccato --->
    <div v-if="ha_cliccato">
      <v-row class="justify-center">
        <v-card color>
          <v-card-text>
            <div class="headline mb-2">Grazie!</div>
            La tua opinione è importante per noi
          </v-card-text>
        </v-card>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "../firebase_config";

export default {
  data: () => ({
    items: [1, 2, 3, 4],
    url: "https://api.eoscaffe.it:8443/vota",
    voto: 0,
    email: "",
    cli_cod: "",
    canale: "",
    matricola: "",
    messaggio: "",
    blocca_invio: true,
    ha_cliccato: false,
    colore_selezionato: { "background-color": "	#00FF7F" },
    colore_sfondo: { "background-color": "white" },
    messaggioRules: [
      (v) => !!v || "Dato obbligatorio",
      (v) => v.length <= 30 || "Lunghezza massima 30 caratteri",
      (v) =>
        v.length >= 3 || "Per favore cerca di essere più specifico. grazie",
    ],
    coordinate: null,
  }),
  mounted: function () {
    this.cli_cod = this.$route.query.cli_cod;
    this.matricola = this.$route.query.matricola;
    this.canale = this.$route.query.canale;

    this.$getLocation({
      enableHighAccuracy: true, //defaults to false
      timeout: 10000, //defaults to Infinity
      maximumAge: 0, //defaults to 0
    }).then((coordinates) => {
      console.log(coordinates);
      this.coolatlng = coordinates.lat + "," + coordinates.lng;
    });
  },
  methods: {
    vota: function (voto) {
      this.voto = voto;
      this.blocca_invio = false; //sblocca bottone

      this.$vuetify.goTo(0);

      firebase.analytics().logEvent("select_content", {
        content_type: "image",
        content_id: "voto",
        items: [{ voto: this.voto }],
      });
    },
    inviaVoto: function () {
      const dateTime = +new Date();
      const timestamp = Math.floor(dateTime);

      this.ha_cliccato = true;
      this.$vuetify.goTo(0);
      axios
        .get(this.url, {
          params: {
            datainvio: timestamp,
            voto: this.voto,
            cli_cod: this.cli_cod,
            email: this.email,
            matricola: this.matricola,
            messaggio: this.messaggio,
            canale: this.canale,
            coolatlng: this.coolatlng,
          },
        })
        .then((response) => {
          console.log("inviato: " + response);
          this.inviato = true;
        })
        .catch((e) => {
          console.log("errore " + e);
        });
    },
    indietro: function () {
      this.voto = 0;
      this.blocca_invio = true;
      this.ha_cliccato = false;
    },
  },
};
</script>
<style scoped>
.colore_selezionato {
  background-color: "green";
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    matricola: ''
  },
  mutations: {
    updateMatricolaAttuale(state, m) {
      state.matricola = m
    }
  },
  getters: {
    getMatricolaAttuale: state => state.matricola
  }
})
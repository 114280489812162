<template>
  <v-app id="inspire" fluid class="fill-height">
    <v-navigation-drawer v-model="drawer" fixed app>
      <v-list class="pt-0">
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon color="orange">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed app class="amber">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="1 == 1"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>EOS App</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-img
        class="mx-2"
        src="/img/logo2020.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
    </v-app-bar>
    <v-content>
      <router-view></router-view>
    </v-content>
    <br /><br /><br />
  </v-app>
</template>
<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    drawer: null,
  }),
  computed: {
    menuItems() {
      let menuItems = [
        { title: "Home", path: "/", icon: "home", visible: true },
        { title: "Assistenza", path: "/help", icon: "help", visible: true },
        { title: "Vota", path: "/vota", icon: "thumb_up", visible: false },
        { title: "Contatti", path: "/contatti", icon: "phone", visible: true },
        { title: "Dove Siamo", path: "/dovesiamo", icon: "map", visible: true },
        { title: "Info", path: "/info", icon: "info", visible: true },
      ];
      return menuItems.filter((item) => {
        if (item.visible == true) {
          return item;
        }
      });
    },
  },
};
</script>
